<template>
  <div class="home">
    <div class="topChange">
      <div class="boxChange" :style="car == 1 ? 'font-size: 18px;color:#171717;border-bottom:2px solid #5473E8;' : ''" @click="() => { car = 1; getnums() }">
        <p>རིག་ཚན་དང་པོ།</p>
        <p>科目一</p>
      </div>
      <div class="boxChange" :style="car == 2 ? 'font-size: 18px;color:#171717;border-bottom:2px solid #5473E8;' : ''" @click="() => { car = 2; getnums() }">
        <p>རིག་ཚན་བཞི་བ།</p>
        <p>科目四</p>
      </div>
    </div>
    <div class="exam1" @click="qukaoshi(2)">
      <div class="sequentialIcon">
        <img src="../assets/image/sequential.png" alt="">
      </div>
      <p class="sequential">གོ་རིམ་སྦྱོང་བརྡར་།</p>
      <p class="sequentialZw">顺序练习</p>
    </div>
    <div class="exam2" @click="qukaoshi(1)">
      <div class="simulationIcon">
        <img src="../assets/image/examine.png" alt="">
      </div>
      <p class="simulation">ཚོད་ལྟའི་རྒྱུགས།</p>
      <p class="simulationZw">模拟考试</p>
    </div>
    <!-- {{code}} -->
    <div class="bottomMenu">
      <!-- 视频讲解 -->
      <div class="videoSile" @click="gospecial('/videoExplanation')">
        <img src="../assets/image/videosea.png" alt="">
        <p class="nameSea">བརྙན་ལམ་སློབ་ཁྲིད།</p>
        <p>视频讲解</p>
      </div>
      <div class="line"></div>
      <!-- 专享练习 -->
      <div class="exclusive" @click="gospecial('/specialProject')">
        <img src="../assets/image/special.png" alt="">
        <p class="nameSea">ཆེད་དོན་་སྦྱོང་བརྡར།</p>
        <p>专项练习</p>
      </div>
      <div class="line"></div>
      <!-- 错题联系 -->
      <div class="wongWatch" @click="gospecial('/mastickandCollect')">
        <img src="../assets/image/mistakes.png" alt="">
        <p class="nameSea">འདྲི་གཞི་ནོར་བ་སྦྱོང་བརྡར།</p>
        <p>错题练习</p>
      </div>
      <div class="line"></div>
      <!-- 考场模拟 -->
      <div class="examinationRoom" @click="gotoNext('/examination1')">
        <img src="../assets/image/examinRoom.png" alt="">
        <p class="nameSea">ཚོད་ལྟའི་རྒྱུགས།</p>
        <p>考场模拟</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { countNum, countList, getcacheTopic, countOne, countFour, isSupperVip, getCache } from '@/api/count';
import { vip } from '@/api'
export default {
  data() {
    return {
      code: '',
      car: 1,
      numbers: ''
    }
  },
  created() {
    this.car = this.$route.query.id || 1
    this.getnums()
  },
  methods: {
    /* 获取题目 */
    async getTopic() {
      try {
        this.message = '加载中....'
        this.forbidClick = true
        const res = await countNum(this.car)
        const listRes = await countList({ pageNum: 1, pageSize: res.data, subject: this.car })
        if (listRes.httpStatus !== 200) {
          throw new Error(`服务器返回状态码异常: ${listRes.httpStatus}`)
        }
        // 使用循环来避免重复代码
        const parseMedia = (mediaType, items) => {
          items.forEach(item => {
            if (!item[mediaType] || item[mediaType].length === 0) {
              item[mediaType] = []
            } else if (item[mediaType][0].indexOf('uid') !== -1) {
              try {
                item[mediaType][0] = JSON.parse(item[mediaType][0])
              } catch (e) {
                console.error(`解析${mediaType}失败:`, e)
              }
            }
          })
        }
        parseMedia('audios', listRes.data.list)
        parseMedia('videos', listRes.data.list)
        listRes.data.list.forEach(item => {
          item.result = []
          item.cutindex = null
          item.trueIndex = null
          item.isSelect = false
        });
        if (listRes.data.list.length == 0) {
          Dialog.alert({
            title: '提示',
            message: '暂无题目,正在升级敬请期待~'
          })
          return
        } else {
          localStorage.setItem('tixing', JSON.stringify(listRes.data.list))
          this.$router.push({
            path: '/exercise',
            query: {
              id: this.car
            }
          })
        }
      } catch (e) {
        console.error(e)
        // 根据实际需求，可能需要向用户显示错误信息
      } finally {
        // 无论成功或失败，最后都释放加载状态
        this.message = ''
        this.forbidClick = false
      }
      // this.$toast.loading({
      //   message: '加载中...',
      //   forbidClick: true,
      // })
      // countNum(this.car).then(res => {
      //   countList({ pageNum: 1, pageSize: res.data, subject: this.car }).then(res => {
      //     if (res.httpStatus === 200) {
      //     (res.data.list).forEach(item => {
      //       if (!item.audios || (item.audios).length === 0) {
      //         item.audios = []
      //       } else if (item.audios[0].indexOf('uid') !== -1) {
      //         item.audios[0] =  JSON.parse(item.audios[0])
      //       }
      //       if (!item.videos || item.videos.length == 0) {
      //         item.videos = []
      //       } else if (item.videos[0].indexOf('uid') !== -1) {
      //         item.videos[0] =  JSON.parse(item.videos[0])
      //       }
      //       if (!item.imgList || item.imgList.length == 0) {
      //         this.imgList = []
      //       } else if (item.imgList[0].indexOf('uid') !== -1) {
      //         item.imgList[0] =  JSON.parse(item.imgList[0])
      //       }
      //       item.result = []
      //       item.cutindex = null
      //       item.trueIndex = null
      //       item.isSelect = false
      //     });
      //     localStorage.setItem('tixing', JSON.stringify(res.data.list))
      //     this.$router.push({
      //       path: '/exercise',
      //       query: {
      //         id: this.car
      //       }
      //     })
      //   } else {
      //   }
      //   })
      // })
    },
    // 获取多少个数量
    getnum() {
      const that = this
      getcacheTopic({subject: that.car}).then(res => {
        if (res.data !== 'null') {
          Dialog.confirm({
            title: '提示',
            message: 'འདྲི་གཞི་སྔོན་མ་ཚར་བའི་མུ་མཐུད་དུ་ཞིབ་བཤེར་བྱེད་དམ།?',
            confirmButtonText: 'གཏན་འཁེལ།',
            cancelButtonText: 'མེད་པར་བཟོ་བ།'
          }).then(() => {
            getCache({subject: that.car}).then(() => {})
            const dataJson = JSON.parse(res.data)
            localStorage.setItem('tixing', dataJson.tixing)
            localStorage.setItem('errorList', dataJson.errorList)
            localStorage.setItem('rightList', dataJson.rightList)
            localStorage.setItem('indexcount', dataJson.indexcount)
            this.$router.push({
              path: '/exercise',
              query: {
                cache: this.car
              }
            })
          }).catch(() => {
            this.getTopic()
            getCache({subject: that.car}).then(() => {})
          })
        } else {
          this.getTopic()
          getCache({subject: that.car}).then(() => {})
        }
      })
      
    }, 
    // 获取数量
    getnums() {
      countNum(this.car).then(res => {
        this.numbers = res.data
      })
    },
    qukaoshi(e) {
      switch (e) {
        case 1:
          localStorage.setItem('isSxulianxi', false)
          this.$router.push({
            path: '/testscore',
            query: {
              id: this.car
            }
          })
          break;
        case 2:
        localStorage.setItem('isSxulianxi', true)
          vip().then(res => {
            localStorage.setItem('vip', res.data)
          })
          this.getnum()
          break;
        default:
          break;
      }
    },
    /* 下面个=四个跳转 */
    gospecial(e) {
      localStorage.setItem('isSxulianxi', false)
      isSupperVip().then(res => {
        if (res.data) {
          if (e === '/examination1') {
            vip().then(res => {
              localStorage.setItem('vip', res.data)
            })
            if (this.car == 1) {
              this.getcount();
            } else {
              this.getfour();
            }
          } else {
            this.$router.push(`${e}?id=${this.car}`)
          }
        } else {
          this.$router.push({
            path: "/pay",
            query: {
              ip: 1
            }
          })
        }
      })
    },
    gotoNext(e) {
      isSupperVip().then(res => {
        if (res.data) {
          this.$router.push(`${e}?id=${this.car}`)
        } else {
          this.$router.push({
            path: "/pay",
            query: {
              ip: 1
            }
          })
        }
      })
    },
    // 获取科一考试题目
    getcount() {
      countOne().then((res) => {
        // this.countList = res.data;
        // this.countForm = this.countList[0];
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          console.log(res.data);
          localStorage.setItem('tixing', JSON.stringify(res.data))
          this.$router.push({
            path: '/examination1',
            query: {
              id: this.car
            }
          })
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
    // 获取科四考试题目
    getfour() {
      countFour().then((res) => {
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          localStorage.setItem('tixing', JSON.stringify(res.data))
          this.$router.push({
            path: '/examination1',
            query: {
              id: this.car
            }
          })
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.home{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topChange{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    .boxChange{
      width: 30%;
      height: 50px;
      text-align: center;
      color: #6E7278;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
    }
  }
  .exam1{
    width: 138px;
    height: 138px;
    margin-top: 38px;
    background: #6493FF;
    box-shadow: 0px 0px 10px 1px #6493FF;
    opacity: 1;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sequentialIcon{
      width: 50px;
      height: 50px;
      margin-top: -8px;
      img{
        width: 59px;
        height: 59px;
      }
    }
    .sequential{
      height: 20px;
      margin-top: 12px;
      font-size: 16px;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 800;
      color: #FFFFFF;
      line-height: 0px;
      text-align: center;
    }
    .sequentialZw{
      width: 70px;
      height: 9px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      color: #FFFFFF;
      line-height: 0;
      text-align: center;
    }
  }
  .exam2{
    width: 138px;
    height: 138px;
    margin-top: 38px;
    background: #22D7B3;
    box-shadow: 0px 0px 10px 1px #22D7B3;
    opacity: 1;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .simulationIcon{
      width: 50px;
      height: 50px;
      margin-top: -8px;
      img{
        width: 59px;
        height: 59px;
      }
    }
    .simulation{
      height: 20px;
      margin-top: 12px;
      font-size: 16px;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 800;
      color: #FFFFFF;
      line-height: 0px;
      text-align: center;
    }
    .simulationZw{
      width: 70px;
      height: 9px;
      text-align: center;
      font-size: 13px;
      font-family: PingFang SC, PingFang SC;
      color: #FFFFFF;
      line-height: 0;
    }
  }
  .bottomMenu{
    width: 363px;
    height: 131px;
    margin-top: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 18px 18px 18px 18px;
    opacity: 1;
    div{
      width: 84px;
      height: 131px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .nameSea{
        padding: 4px 0;
      }
    }
    img{
      width: 41px;
      height: 37px;
      margin-top: 30px;
    }
    .line{
      width: 1px;
      height: 58px;
      opacity: 1;
      border-right: 1px solid #F5F5F5;
    }
  }
}
</style>
